.app {
  height: 100vh;

  display: grid;
  grid-template-rows: auto 1fr auto;

  grid-template-areas:
    "header"
    "main"
    "footer";
  grid-gap: 10px;

  header {
    grid-area: header;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .title {
      text-decoration: none;
      font-size: 2rem;
    }
    * {
      margin: 0 1rem 0.5rem;
    }
    input {
      margin: 0;
      background-color: transparent;
      color: white;
      border: none;
      border-width: 0;
      padding: 0.5rem;
      background-color: #777;
      border-radius: 0.3rem;
      &::placeholder {
        color: #999;
        opacity: 1; /* Firefox */
      }
    }
    ul {
      margin: 0;
      padding: 0;
      display: block;
      li {
        margin: 0;
        padding: 0;
        display: block;
      }
    }
  }

  main {
    grid-area: main;
    overflow-y: auto;
  }

  footer {
    padding-bottom: 0.1rem;
    font-size: 80%;
    grid-area: footer;
    text-align: center;
    white-space: nowrap;
    overflow-x: hidden;
  }
}
