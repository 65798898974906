$titleSize: 1rem;

.card {
  border-radius: 0.3rem;
  overflow: hidden;
  background-color: white;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.card-grid {
  max-width: 100%;

  display: grid;
  grid-template-columns: repeat(auto-fit, 14rem);

  justify-content: center;
  grid-gap: 1rem;

  .card-container {
    display: flex;
  }
}
