body {
  margin: 0;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  background: #555;
  color: #bbb;
}

a {
  color: #ccc;
}
